import React, { useEffect, useState, Fragment } from 'react';
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/20/solid';

interface FlashMessageComponentProps {
  messages: Record<string, string[]>;
}

const FlashMessageComponent: React.FC<FlashMessageComponentProps> = ({ messages }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div id="flash-messages-container" class="tw-fixed tw-top-16 tw-right-5 tw-z-5 tw-w-full tw-max-w-md tw-flex tw-justify-end tw-transform tw--translate-y-1/5">
      {isVisible && Object.entries(messages).map(([key, message]) => (
        <div className="tw-pointer-events-auto tw-w-full tw-max-w-sm tw-overflow-hidden tw-rounded-lg tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5">
          <div className="tw-p-4">
            <div className="tw-flex tw-items-start">
              <div className="tw-flex-shrink-0">
              {key === 'notice' ? (
                <CheckCircleIcon className="tw-h-6 tw-w-6 tw-text-success" aria-hidden="true" />
              ) : (
                <ExclamationTriangleIcon className="tw-h-6 tw-w-6 tw-text-primary" aria-hidden="true" />
              )}
                
              </div>
              <div className="tw-ml-3 tw-w-0 tw-flex-1 tw-pt-0.5">
                <p className="tw-text-sm tw-font-bold tw-text-gray-900">{ message }</p>
              </div>
              <div className="tw-ml-4 tw-flex tw-flex-shrink-0">
                <button
                  type="button"
                  className="tw-inline-flex tw-rounded-md tw-bg-white tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-indigo-500 focus:tw-ring-offset-2"
                  onClick={() => {
                    setIsVisible(false)
                  }}
                >
                  <span className="tw-sr-only">Close</span>
                  <XMarkIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FlashMessageComponent;
